.fui-app-bar {
  z-index: 9;
  box-sizing: border-box;
  position: fixed;
  align-items: center;
  width: 100%;
  height: 6rem;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);

  &:before {
    background-color: #b93499;
    background-image: -webkit-gradient(linear,left top,right top,from(#6100a5),color-stop(#8241aa),color-stop(#b93499),to(#ec0677));
    background-image: linear-gradient(90deg,#6100a5,#8241aa,#b93499,#ec0677);
    content: "";
    display: block;
    height: .25em;
    position: relative;
    width: 100%;
  }

  &__content {
    display: flex;
    flex-grow: 1;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  &__logo {
    width: 280px;
    height: 40px;
    margin: 0 1rem;
    @media (min-width: 768px) {
      margin: 0 5rem;
    }
  }

  &__menu {
    cursor: pointer;
    padding-left: 1rem;
    width: 20px;
    height: 20px;
    @media (min-width: 768px) {
      display: none;
    }
  }

  &__lang {
    position: relative;
    right: 0;
    margin-right: 2rem;
  }

  &__lang-switch {
    cursor: pointer;
    font-weight: 500;
    &:hover {
      color: #6100a5;
      text-decoration: underline;
    }
  }
}

.fui-app-menu {
  display: none;
  flex-grow: 1;
  @media (min-width: 768px) {
    display: block;
  }
  &__item {
    cursor: pointer;
    font-size: small;
    color: #515e6d;
    padding: 1.5rem;
    font-weight: 500;
    box-sizing: border-box;
    transition: all .5s;
    border-bottom: 2px solid rgba(97,0,165,0);
    &:hover {
      border-bottom: 2px solid rgba(97,0,165,1);
    }
  }
}

.fui-app-drawer {
  &__header {
    color: #515e6d;
    font-size: 1.2rem;
    font-weight: bold;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 1.2rem;
    border-bottom: 1px solid #E8EAEC;
  }

  &__close {
    cursor: pointer;
    padding-left: 1rem;
    width: 20px;
    height: 20px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }
}