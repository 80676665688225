body {
  margin: 0;
  font-family: "Work Sans",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f4f5;
}

.h2, h2 {
  font-size: 1.25em;
  text-transform: uppercase;
  letter-spacing: .1em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
