.fui-drawer {
  position: fixed;
  top: 0;
  z-index: 99;
  background: white;
  box-shadow: 0 1px 2px 0 rgba(46,62,79,0.1);
  width: 100vw;
  height: 100vh;
  transition: transform 0.5s cubic-bezier(0.4, 0.0, 0.2, 1);
  border-radius: 0;

  &--left {
    left: 0;
    transform: translateX(-100%);
  }

  &--right {
    right: 0;
    transform: translateX(100%);
  }

  &--opened {
    transform: none;
  }
}

@media (min-width: 768px) {
  .fui-drawer {
    width: auto;
    min-width: 300px;
  }

  .fui-drawer-overlay {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 98;
    display: none;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.04);

    &--show {
      display: block;
    }
  }
}

.fui-drawer-header {
  flex-grow: 1;
}

