.fui-homepage {
    position: relative;
    overflow-y: scroll;
    min-height: 100vh;
    &__social {
        margin-left: 1rem;
    }
    &__footer {
        width: 100%;
        padding: 2rem;
        height: 140px;
        box-sizing: border-box;
        background-color: #2e3e4f;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: .8rem;
    }
    &__hero {
        margin-top: 6rem;
        background-image: url("../../assets/hero.jpeg");
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 600px;
    }

    &__hero-title {
        font-size: 2.5rem;
        color: #f3f4f5;
        text-align: center;
    }

    &__hero-sub-title {
        text-align: center;
        font-size: 1.5rem;
        color: #f3f4f5;
        padding-bottom: 2rem;
    }


    &__overlay {
        background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), rgba(0, 0, 0, 0.5) 130%);
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    &__wave {
        background-image: url("../../assets/wave.jpeg");
        width: 100%;
        background-repeat: no-repeat;
        @media (min-width: 768px) {
            padding: 8rem 0;
        }
    }

    &__products {
        padding: 3rem 0;
        @media (min-width: 768px) {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.fui-homepage-section-title {
    color: #2e3e4f;
    text-align: center;
    padding-top: 5rem;
}


.fui-homepage-section {
    min-height: 400px;
    background: white;
}

.fui-homepage-section, .fui-homepage-strengths {
    width: 100%;
    @media (min-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.fui-homepage-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 768px) {
        flex-direction: row;
    }
}

.fui-homepage-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    max-height: 24rem;
    padding: 3rem;
    &__icon {
        cursor: pointer;
        max-width: 16rem;
        max-height: 20rem;
        padding: 0.5rem;
        box-shadow: 0 0 0.5em 0 rgba(54,67,77,.2);
        transition: all .2s;
        &:hover {
            box-shadow: 0 0 8px rgba(0,0,0,.6);
            max-width: 17rem;
            max-height: 21rem;
        }
    }
    &__title {
        padding: 1rem;
        font-size: 1.2rem;
        color: #2e3e4f;
        font-style: normal;
        font-weight: 500;
        text-rendering: optimizeLegibility;
        text-align: center;
    }
    &__sub-title {
        color: #515e6d
    }
}

.fui-homepage-product {
    cursor: pointer;
    margin: 1rem;
    max-width: 400px;
    background: white;
    border: .0625em solid #dcdfe1;
    border-radius: .25em;
    -webkit-box-shadow: 0 0 0.5em 0 rgba(54,67,77,.2);
    box-shadow: 0 0 0.5em 0 rgba(54,67,77,.2);
    height: 500px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #2e3e4f;
    &:hover {
        box-shadow: 0 0 8px rgba(0,0,0,.6);
    }
    &__features {
        padding: 1rem;
    }
    &__banner {
        box-sizing: border-box;
        width: 100%;
        padding: .125em 1em;
        &--1st {
            background-color: #ec0677;
        }
        &--2nd {
            background-color: #b93499;
        }
        &--3rd {
            background-color: #6100a5;
        }
    }

    &__title {

    }

    &__sub-title {

    }

    &__img-container {
        border-radius: 50%;
        width: 150px;
        height: 150px;
        overflow: hidden;
    }

    &__img {
        max-width: 100%;
        height: auto;
        display: block;
    }

    &__feature {
        display: flex;
        align-items: center;
        padding: .2rem;
        > img {
            width: 15px;
            height: 15px;
            padding-right: 5px;
        }
    }
}

.fui-homepage-social-icon {
    padding: .3rem;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.fui-homepage-cases {
    .react-photo-gallery--gallery{
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 600px;
    }
}

.fui-homepage-contacts {
    display: flex;
    justify-items: center;
    align-items: center;
    flex-wrap: wrap;
}

.fui-homepage-contact {
    cursor: pointer;
    margin: 1rem;
    max-width: 400px;
    background: white;
    border: .0625em solid #dcdfe1;
    border-radius: .25em;
    padding: 2rem;
    -webkit-box-shadow: 0 0 0.5em 0 rgba(54,67,77,.2);
    box-shadow: 0 0 0.5em 0 rgba(54,67,77,.2);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #2e3e4f;
    &:hover {
        box-shadow: 0 0 8px rgba(0,0,0,.6);
    }
    &__features {
        padding: 1rem;
    }
    &__banner {
        box-sizing: border-box;
        width: 100%;
        padding: .125em 1em;
        &--1st {
            background-color: #ec0677;
        }
        &--2nd {
            background-color: #b93499;
        }
        &--3rd {
            background-color: #6100a5;
        }
    }

    &__title {

    }

    &__sub-title {

    }

    &__img-container {
        border-radius: 50%;
        overflow: hidden;
    }

    &__img {
        width: 10rem;
        height: 10rem;
        height: auto;
        display: block;
    }

    &__feature {
        display: flex;
        align-items: center;
        padding: .2rem;
        > img {
            width: 15px;
            height: 15px;
            padding-right: 5px;
        }
    }
}
