.fui-button {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: .7rem 2.25rem;
  -webkit-appearance: none;
  border-radius: .25em;
  line-height: 1;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  outline: none;
  position: relative;
  text-decoration: none;
  color: #fff;
  border: .165em solid hsla(0,0%,100%,.8);
  background: transparent;
  &:hover {
    border-color: #f3f4f5;
    color: #f3f4f5;
  }
}